// user.js
import router from '@/router';
import {userLoginApi, userLogoutApi} from "@/api/admin/userApi"
import {setToken} from '@/utils/auth';
import Swal from 'sweetalert2';

const state = {
    userInfo: {

    },
};

const mutations = {

};

const actions = {
    // 用户退出登录
    async loginOut({ commit }, parame) {
        return new Promise(async (resolve, reject) => {
            if (parame) {
                Swal.fire({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    icon: 'success',
                    title: '退出成功'
                })
                // let { data } = await userLogoutApi();
            }
            router.push('/login');
            resolve();
        });
    },
    // 用户登录
    async userLogin({ commit ,dispatch}, parame) {
        let { data } = await userLoginApi(parame);
        console.log(data)
        setToken(data)
        Swal.fire({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            icon: 'success',
            title: '登录成功'
        })
        router.push('/');
        location.reload();
    },
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
