import {commentType} from "@/utils/commentType";
import {getCategory1ListApi, getCategoryListApi,getCategoryListByTopicApi,getCategory1ListByTopicApi } from "@/api/admin/categoryApi";
import {clearTopicApi, deleteGoodsApi, getSelectedPageApi, updateSortApi, getSelectedTotalApi, getGoodsPriceTrendApi, updateTopicGoodsDisPriceApi} from "@/api/admin/topicApi";
import { editDisPriceApi } from "@/api/backend/goodsManageApi.js";
import {exportExcelApi} from "@/api/backend/export"
import ObjectUtil from "@/utils/ObjectUtil";
import sd from "silly-datetime";
import {findBackendJdRegionApi, getGoodsDetailByIdsApi} from "@/api/backend/addressApi";

/**
 * 选品商品
 */
export const mixins = {
    name: "goodsPremium",
    data() {
        return {
            dialog: false,
            item: {
                topicId: "",
                title: "",
                id: "",
            },
            index: 1,
            addressDialog: false,
            selectionGoods: {
                status: 1,
                supplyRate: "",
                scribingType: "",
                scribingRatio: 0.8,
                topicId: "",
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
                priceSort: "",
                sortOrder: ""
            },
            categoryId1: [],
            categoryId2: [],
            selectGoods: [],
            total: 999,
            loading: false,
            excelLoading: false,
            idArr: [],
            addressObj: {
                provinceId: "",
                cityId: "",
                countyId: "",
                townId: ""
            },
            addressList: {
                provinceList: [],
                cityList: [],
                countyList: [],
                townList: []
            },
            skuListFiler: [],
            selGoodsList: [],
            rate: 1.25,
            jdRate: 1,
            systemRate: 0.800,
            jdPercent: 0.98,
            chartDialogVisible: false,
            chartData: {}
        };
    },
    props: {
        items: {}
    },
    watch: {
    },
    computed: {},
    filters: {},
    methods: {
        async viewPriceTrend(item) {
            let {data} = await getGoodsPriceTrendApi({skuId: item.skuId});
            this.chartData = {
                ...data,
                skuName: item.title
            };
            this.chartDialogVisible = true;
        },
        chartDialogVisibleFn(val){
            this.chartDialogVisible = val;
        },
        /**
         * 修改表格颜色
         */
        handleCommand(command) {
            const row = this.scope?.row; // 确保能拿到行的数据
            switch (command) {
                case "setSort":
                    this.setSort(row); // 执行修改顺序操作
                    break;
                case "viewPriceTrend":
                    // 执行查看价格趋势的操作
                    break;
                case "delGoods1":
                    this.delGoods(row, 1); // 执行删除当前库该商品的操作
                    break;
                case "delGoods2":
                    this.delGoods(row, 2); // 执行删除所有库该商品的操作
                    break;
            }
        },
        //  放大图片
        getSrcList(cardPicUrl) {
            if (cardPicUrl) {
                return cardPicUrl.split(',');
            }
        },
        checkClickUrl(item) {
           if (!item) {
               return;
           }
            const newWindow = window.open(item.jdUrl, '_blank');

            // 设定一个定时器，检查新页面的 URL 是否被重定向
            const checkRedirect = setInterval(() => {
                try {
                    let currentUrl = newWindow.location.href;
                    // 如果新窗口已经加载并且 URL 改变
                    if (currentUrl.includes('https://trade.m.jd.com/common/limit.html?module=detail_m1')) {
                        console.log('重定向到指定页面')
                        // 如果重定向到指定页面，执行特定操作
                        clearInterval(checkRedirect);
                        this.editDisPrice(item);
                    }
                    console.log('当前页面的 URL:', newWindow.location.href)
                } catch (e) {
                    // 浏览器的跨域限制可能会阻止访问 location.href
                    // 在跨域时，允许进行轮询，直到可以访问新窗口的 location 或者关闭
                    // 代表被重定向了 直接调用相关方法
                    clearInterval(checkRedirect);
                    this.editDisPrice(item);
                }

                // 如果新窗口关闭，停止轮询
                if (newWindow.closed) {
                    clearInterval(checkRedirect);
                }
            }, 1000); // 每秒检测一次
        },
        async editDisPrice(item) {
            let skuIdList = item;
            if (this.selectGoods) {
                skuIdList = this.selectGoods.map(item => item.skuId);
            }
            let requestData = {
                "skuIdList": skuIdList
            };
            // await editDisPriceApi({skuId: item.skuId});
            await editDisPriceApi(requestData);
            this.getList();
        },
        //  修改价格
        editRate() {
            if (this.rate) {
                this.rate = Number(this.rate);
                // this.getList();
                this.systemRate = (Number(this.selectGoods[0].supplPrice) / (Number(this.selectGoods[0].supplPrice) * this.rate)).toFixed(3)
                return;
            }
            if (this.jdRate) {
                this.jdRate = Number(this.jdRate);
                // this.getList();
                // this.systemRate = (this.selectGoods[0].disPrice / (this.selectGoods[0].disPrice * this.rate)).toFixed(3)
            }
        },

        tableRowClassName({row}) {

            if (row.fromTopicId === 1) {
                return 'exit-row';
            }

            if (row.status == 2) {
                return 'success-row';
            }

            if (row.price * this.jdRate >= row.supplPrice * this.rate) {
                return 'success-row';
            } else {
                return 'warning-row';
            }
        },
        provinceChange(id) {
            this.findBackendJdRegion("cityList", id);
            this.addressObj.cityId = ""
            this.addressObj.countyId = ""
            this.addressObj.townId = ""
            this.addressList.cityList = []
            this.addressList.countyList = []
            this.addressList.townList = []
        },
        cityChange(id) {
            this.findBackendJdRegion("countyList", id);
            this.addressObj.countyId = ""
            this.addressObj.townId = ""
            this.addressList.countyList = []
            this.addressList.townList = []
        },
        countyChange(id) {
            this.findBackendJdRegion("townList", id);
            this.addressObj.townId = ""
            this.addressList.townList = []
        },
        addressDialogOpen() {
            this.addressObj.provinceId = localStorage.getItem("provinceId") || ""
            this.addressObj.cityId = localStorage.getItem("cityId") || ""
            this.addressObj.countyId = localStorage.getItem("countyId") || ""
            this.addressObj.townId = localStorage.getItem("townId") || ""

            this.findBackendJdRegion("provinceList", 0);
            if (this.addressObj.provinceId) {
                this.findBackendJdRegion("cityList", this.addressObj.provinceId);
            }
            if (this.addressObj.cityId) {
                this.findBackendJdRegion("countyList", this.addressObj.cityId);
            }
            if (this.addressObj.countyId) {
                this.findBackendJdRegion("townList", this.addressObj.countyId);
            }
        },
        async findBackendJdRegion(type, id) {
            let {data} = await findBackendJdRegionApi({
                id
            })
            this.addressList[type] = data
        },
        /**
         * 设置发货地址
         */
        async setJdAddress() {
            if (this.addressObj.provinceId) {
                localStorage.setItem("provinceId", this.addressObj.provinceId)
            }
            if (this.addressObj.cityId) {
                localStorage.setItem("cityId", this.addressObj.cityId)
            }
            if (this.addressObj.countyId) {
                localStorage.setItem("countyId", this.addressObj.countyId)
            }
            if (this.addressObj.townId) {
                localStorage.setItem("townId", this.addressObj.townId)
            }
            this.addressDialog = false
            this.$message({
                type: 'success',
                message: '保存成功!'
            });
            this.getList();
        },
        /**
         * 导出文件
         */
        async exportExcel() {
            this.excelLoading = true;
            const response = await exportExcelApi({
                topicId: this.item.id,
                current: 1,
                size: 10,
                isJd: 1
            })
            const blob = new Blob([response], {type: 'application/octet-stream'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${sd.format(new Date())}_已选商品.xlsx`; // 设置下载的文件名，根据需要修改
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.excelLoading = false;
        },
        /**
         * 批量删除商品
         */
        batchDelGoods() {
            this.$confirm('确定删除已选商品', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                for (const e of this.idArr) {
                    await deleteGoodsApi({
                        goodsId: e,
                        topicId: this.item.id,
                        removeType: 1,
                        isJd: 1
                    })
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.queryGetList();
            })
        },
        /**
         * 删除已选商品
         */
        async delGoods(item,removeType) {
            if (removeType === 1) {
                await this.$confirm('确定删除当前库中该商品', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await deleteGoodsApi({
                        goodsId: item.id,
                        topicId: this.item.id,
                        removeType: removeType,
                        isJd: item.isJd
                    })
                })
            } else if (removeType === 2) {
                await this.$confirm('确定删除所有库中该商品', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await deleteGoodsApi({
                        goodsId: item.id,
                        topicId: this.item.id,
                        removeType: removeType,
                        isJd: item.isJd
                    })
                })
            }
            /*await deleteGoodsApi({
                goodsId: item.id,
                topicId: this.item.id
            })
            ;*/
            this.$message({
                type: 'success',
                message: '删除成功!'
            });
            this.queryGetList();
        },
        /**
         * 获取选择商品ID
         * @param val
         */
        handleSelectionChange(val) {
            this.idArr = val.map(item => item.id)
        },
        /**
         * 修改排序
         */
        setSort(item) {
            this.$prompt('请输入排序号', '修改排序', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item.sort
            }).then(async ({value}) => {
                let obj = {
                    goodsId: item.id,
                    sort: value,
                    topicId: this.item.id
                }
                await updateSortApi(JSON.stringify(obj));
                this.queryGetList()
            })
        },
        async setTopicGoodsDisPrice(item) {
            this.$prompt('请输入排序号', '修改排序', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item.disPrice
            }).then(async ({value}) => {
                let obj = {
                    goodsId: item.id,
                    disPrice: value,
                    disPriceType: 1,
                    topicId: this.item.id
                }
                await updateTopicGoodsDisPriceApi(JSON.stringify((obj)));
                this.$message({
                    type: 'success',
                    message: '修改成功!'
                });
                this.queryGetList()
            })
        },
        /**
         * 清空选品库
         */
        clearSelGoods() {
            this.$confirm('确定清空当前选品库吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {data} = await clearTopicApi({
                    id: this.selectionGoods.topicId
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.queryGetList();
            })
        },
        /**
         * 切换列表
         */
        switchGoods(index) {
            this.index = index;
            if (index === 1) {
                this.queryGetList();
            } else {
                this.$emit("SwithGoods", index)
            }
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.selectionGoods.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.selectionGoods.current = val;
            this.getList();
        },
        /**
         * 评论类型
         */
        commentType() {
            return commentType
        },
        /**
         * 条件查询
         */
        queryGetList() {
            this.getList()
        },

        /**
         * 查询
         */
        async getList() {
            this.loading = true;
            let config = {
                ...this.selectionGoods,
                sortOrder:this.selectionGoods.sortOrder ? this.selectionGoods.sortOrder.join(',') : ''
            }
            let obj = JSON.parse(JSON.stringify(config));
            ObjectUtil.removeEmptyValues(obj)
            let {data: res} = await getSelectedPageApi(obj)

            this.skuListFiler = []
            /*if (localStorage.getItem("provinceId")) {
                let list = []
                res.rows.forEach(r => {
                    list.push(r.skuId)
                })
                this.addressObj.provinceId = localStorage.getItem("provinceId") || ""
                this.addressObj.cityId = localStorage.getItem("cityId") || ""
                this.addressObj.countyId = localStorage.getItem("countyId") || ""
                this.addressObj.townId = localStorage.getItem("townId") || ""
                let {data} = await getGoodsDetailByIdsApi({
                    provinceId: this.addressObj.provinceId,
                    cityId: this.addressObj.cityId,
                    countyId: this.addressObj.countyId,
                    townId: this.addressObj.townId,
                    skuIdList: list.join(",")
                })
                data.forEach((r, index) => {
                    let arr = JSON.parse(r);
                    arr.forEach((item) => {
                        res.rows[index].addressStatus = item.areaRestrict
                    })
                })
            }*/
            this.selectGoods = res.rows;
            let {data} = await getSelectedTotalApi(obj)
            this.total = data
            this.loading = false;
        },
        /**
         * 初始化组件
         */
        open(item) {
            if (item) {
                this.item = item;
                console.log(item)
                this.selectionGoods.current = 1;
                this.selectionGoods.topicId = item.id;
                this.dialog = true;
                this.initCategory(item.id);
                this.queryGetList()
            }
        },
        /**
         * 初始化分类
         */
        async initCategory(topicId) {
            // let {data} = await getCategoryListApi();
            let {data} = await getCategoryListByTopicApi({ topicId: topicId});
            this.categoryId1 = data.categoryList;
        },
        /**
         * 获取二级分类
         */
        async getCategory1List() {
            // let {data} = await getCategory1ListApi({
            //     name: this.selectionGoods.categoryId1Name, categoryLevel: 1
            // });
            let {data} = await getCategory1ListByTopicApi({
                name: this.selectionGoods.categoryId1Name, categoryLevel: 1, topicId: this.item.id
            });
            this.selectionGoods.categoryId2Name = ""
            this.categoryId2 = data.categoryList;
            this.queryGetList()
        },
        /**
         * 切换商品货源
         */
        isJdChange() {
            this.selectionGoods.current = 1;
            this.queryGetList()
        },
        /**
         * 重置
         */
        reset() {
            this.selectionGoods = {
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
            }
            this.queryGetList();
        }
    },
    mounted() {
        this.open(this.items);
    }
}