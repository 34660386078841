import {commentType} from "@/utils/commentType";
import {getCategory1ListApi, getCategoryListApi} from "@/api/admin/categoryApi";
import {getListTopicApi, getListTopicCountApi, pushAllSelGoodsApi, updateGoodsPriceApi, getTopicListApi, updateSortApi} from "@/api/admin/topicApi";
import ObjectUtil from "@/utils/ObjectUtil";
import SelGoods from "@/views/SelectGoods/components/SelGoods/index.vue";
import {editDisPriceApi} from "@/api/backend/goodsManageApi.js";

/**
 * 选品商品
 */
export const mixins = {
    name: "Goods",
    data() {
        return {
            dialog: false,
            item: {},
            firstPage: true,
            index: 0,
            selectionGoods: {
                status: 1,
                topicId: "",
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
                priceSort: "",
                keywords: "",
                commentOkCount: "",
            },
            markUpVisible: false,
            selectionGoodsDto: {
                rate: 1.25,
                systemRate: 0.800,
                disPrice: 0,
                disPriceType: 1,
                type: "",
                item: ""
            },
            systemRate: 0.800,
            categoryId1: [],
            categoryId2: [],
            selectGoods: [],
            total: 999,
            loading: false,
            rate: 1.25,
            jdRate: 1,

            topicVisible: false,
            topicListVo: [],
            topicListDto: [],
            topicVo: {},
            topicDto: {},

        };
    },
    components: {SelGoods},
    methods: {
        closeTopicVisible() {
            this.topicVisible = false
            this.topicListVo = []
            this.topicListDto = []
        },
        /**
         * 查询选品库
         */
        async getListTopic() {
            let {data} = await getTopicListApi(this.topicDto);
            this.topicListVo = data;
        },
        async editDisPrice(item) {
            let skuIdList = item;
            if (this.selectGoods) {
                skuIdList = this.selectGoods.map(item => item.skuId);
            }
            let requestData = {
                "skuIdList": skuIdList
            };
            // await editDisPriceApi({skuId: item.skuId});
            await editDisPriceApi(requestData);
            this.getList();
        },
        tableRowClassName({row}) {
            if (row.status == 2) {
                return 'warning-row';
            }
            if (((Number(row.supplPrice) * Number(this.rate)) > Number(row.price) * Number(this.jdRate)) || (Number(row.supplPrice) >= Number(row.price) * Number(this.jdRate))) {
                return 'success-row';
            }

            if (((Number(row.supplPrice) * Number(this.rate)) > Number(row.price)) || (Number(row.supplPrice) >= Number(row.price))) {
                return 'warning-row';
            }
        },
        //  修改价格
        editRate(item) {
            if (item && item === 2) {
                if (this.rate) {
                    this.rate = Number(this.rate);
                    // this.getList();
                    this.systemRate = (this.selectGoods[0].supplPrice / (this.selectGoods[0].supplPrice * this.rate)).toFixed(3);
                    return;
                }
            }
            if (this.rate) {
                this.rate = Number(this.rate);
                // this.getList();
                this.systemRate = (this.selectGoods[0].supplPrice / (this.selectGoods[0].supplPrice * this.rate)).toFixed(3);
                return;
            }
            if (this.jdRate) {
                this.jdRate = Number(this.jdRate);
                // this.getList();
                this.systemRate = (this.selectGoods[0].supplPrice / (this.selectGoods[0].supplPrice * this.rate)).toFixed(3);
            }
        },
        /**
         * 真一键挑选
         */
        selTopicPushGoodsAll() {

            let obj = JSON.parse(JSON.stringify(this.selectionGoods));
            obj.minProfitMargin = obj.minProfitMargin / 100;
            obj.maxProfitMargin = obj.maxProfitMargin / 100;
            if (this.item.keywords) {
                obj.keywords = this.item.keywords.trim().split(/\s+/);
            }
            this.loading = true;
            ObjectUtil.removeEmptyValues(obj)
            this.$prompt('请输入加价比例', '加价比例', {
                    confirmButtonText: '挑选',
                    cancelButtonText: '取消',
                    inputValue: 0
            }).then( async ({value}) => {
                obj.disPrice = value;
                console.log(obj)
                let {data} = await pushAllSelGoodsApi(obj);
                this.loading = false
                this.queryGetList();
                this.$message({
                    message: '一键挑选成功',
                    type: 'success'
                });
            }).catch(r => {
                this.loading = false;
            });
        },
        /**
         * 一键挑选
         */
        async selTopicCurrentGoodsAll() {
            for (const item of this.selectGoods) {
                item.disPriceType = 1;
                item.disPrice = 0;
                item.goodsId = item.id;
                item.topicId = this.item.id
                await updateGoodsPriceApi(item)
            }
            this.$message({
                message: '挑选成功',
                type: 'success'
            });
            this.queryGetList();
        },
        /**
         * 切换列表
         */
        switchGoods(index) {
            this.index = index;
            if (index == 0) {
                this.queryGetList();
            }
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.selectionGoods.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.selectionGoods.current = val;
            this.getList();
        },
        /**
         * 评论类型
         */
        commentType() {
            return commentType
        },
        /**
         * 条件查询
         */
        queryGetList() {
            this.firstPage = true;
            this.getList()
        },
        /**
         * 挑选商品
         */ async setTopicCurrentGoodsId(item, type) {
            /*this.$confirm('是否开启保存点？', {
         distinguishCancelAndClose: true,
         confirmButtonText: '是',
         cancelButtonText: '否', //相当于 取消按钮
         type: 'warning'
     }).then(() => {
         log
     }).catch(action => {
     //判断是 cancel (自定义的取消) 还是 close （关闭弹窗）
         if (action === 'cancel'){
             log
         }
     })*/
            this.selectionGoodsDto.item = item;
            this.selectionGoodsDto.type = type;
            this.markUpVisible = true;
        },
        async addGoodsInSelectionTopicGoodsRelation(item, type) {
            if (type === 1) {
                item.topicId = this.item.id;
                item.topicIds = null;
            } else if (type === 2) {
                //  topicIdList 有值就查询 没值只是打开弹窗
                if (this.topicListVo.length <= 0 || this.topicListDto.length <= 0) {
                    // 选到多个选品库
                    this.topicVisible = true;
                    await this.getListTopic();
                    this.topicVo = item;
                    return;
                }
                item.topicIds = this.topicListDto;
                item.topicId = null;
            } else {
                this.$message({
                    message: '请先选择商品',
                    type: 'warning'
                });
                this.topicListDto = [];
                this.topicListVo = [];
                return;
            }
            if (this.selectionGoodsDto.disPrice && this.selectionGoodsDto.disPrice !== 0) {
                item.disPrice = this.selectionGoodsDto.disPrice;
                console.log(1 + ' ' + item.disPrice)
            } else {
                item.disPrice = 0;
                console.log(2 + ' ' + item.disPrice)
            }
            item.disPriceType = 1;
            item.goodsId = item.id;
            await updateGoodsPriceApi(item)
            this.queryGetList();
            if (type === 2) {
                this.topicVisible = false;
            }
            this.markUpVisible = false;
            this.topicListDto = [];
            this.topicListVo = [];

        },
        /**
         * 查询
         */
        async getList() {
            this.loading = true;
            let obj = JSON.parse(JSON.stringify(this.selectionGoods));
            obj.minProfitMargin = obj.minProfitMargin / 100;
            obj.maxProfitMargin = obj.maxProfitMargin / 100;
            if (this.item.keywords) {
                obj.keywords = this.item.keywords.trim().split(/\s+/).join(",");
            }
            ObjectUtil.removeEmptyValues(obj)
            let {data} = await getListTopicApi(obj)
            this.selectGoods = data.rows;
            this.loading = false;
            if (this.firstPage) {
                let {data} = await getListTopicCountApi(obj)
                this.total = data[0]
            }
            this.firstPage = false;
        },
        /**
         * 初始化组件
         */
        open(item) {
            this.index = 0;
            this.item = item;
            this.selectionGoods.current = 1;
            this.selectionGoods.topicId = item.id;
            this.dialog = true;
            this.initCategory();
            this.queryGetList()
        },
        /**
         * 初始化分类
         */
        async initCategory() {
            let {data} = await getCategoryListApi();
            this.categoryId1 = data.categoryList;
        },
        /**
         * 获取二级分类
         */
        async getCategory1List() {
            let {data} = await getCategory1ListApi({
                name: this.selectionGoods.categoryId1Name, categoryLevel: 1
            });
            this.selectionGoods.categoryId2Name = ""
            this.categoryId2 = data.categoryList;
            this.queryGetList()
        },
        /**
         * 切换商品货源
         */
        isJdChange() {
            this.selectionGoods.current = 1;
            this.queryGetList()
        },
        /**
         * 关闭弹窗事件
         */
        closeChange() {
            this.$emit("closeChange");
        },
        /**
         * 重置
         */
        reset() {
            this.selectionGoods = {
                topicId: this.selectionGoods.topicId,
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
            }
            this.queryGetList();
        }
    },
    mounted() {

    }
}