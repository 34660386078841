<template>
  <div style="padding-top: 10px">
    <title-card title="选品专区" style="max-width: 97%; margin: 10px auto">
      <div style="position: absolute; right: 70px">
        <el-button size="mini" type="primary" @click="createTopic">创建选品库</el-button>
      </div>
      <template v-slot:subTitle>

      </template>
    </title-card>
    <div style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px;">
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div class="fw-bolder" style="font-size: 18px">
          选品库列表
        </div>
        <div style="margin-left: 10px">
          <el-input v-model="params.title" placeholder="请输入需要查询的选品库名称" size="mini"
                    style="width: 200px" @keyup.enter.native="getList"></el-input>
        </div>
        <div style="margin-left: 10px">
          <el-input v-model="params.goodsId" placeholder="商品ID所在选品库" size="mini"
                    style="width: 200px" @keyup.enter.native="getList"></el-input>
        </div>
        <div style="margin-left: 10px">
          <el-input v-model="params.goodsTitle" placeholder="商品名称所在选品库" size="mini"
                    style="width: 200px" @keyup.enter.native="getList"></el-input>
        </div>
        <div style="margin-left: 10px">
          <el-switch
              style="display: block"
              v-model="params.isMarkupInterestRate"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="存在自定义利率"
              inactive-text="没用"
              :active-value="1"
              :inactive-value="2"
          >
          </el-switch>
        </div>
        <div style="margin-left: 10px">
          <el-button type="primary" @click="getList(item)" size="mini">搜索</el-button>
        </div>
      </div>
      <div>
        <el-table
            :data="tableData"
            style="width: 100%">

        <el-table-column
              prop="id"
              label="ID"
              width="100">
          </el-table-column>
          <el-table-column
              label="选品库名称"
              width="400">
            <template slot-scope="scope">
              <el-link :underline="false" @click="openTopicInfo(scope.row)">{{ scope.row.title }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
              label="已选择商品"
              width="180">
            <template slot-scope="scope">
              {{scope.row.selectGoodsCount}} 个商品
            </template>
          </el-table-column>
          <el-table-column
              prop="crTime"
              label="创建时间">
          </el-table-column>
          <el-table-column
              width="200px"
              label="商品变化">
            <template slot-scope="scope">
              <el-button type="text" @click="openGoodsChangeByTopicId(scope.row)">查看数据</el-button>
            </template>
          </el-table-column>
          <el-table-column
              width="330px"
              label="操作">
            <template slot-scope="scope">
              <div style="">
                  <el-button size="mini" type="info" @click="copyTopic(scope.row)" style="width: 90px;">复制</el-button>
                <el-button size="mini" type="primary" @click="openEditTopic(scope.row)" style="width: 90px;">编辑</el-button>
                <el-button size="mini" type="danger" @click="deleteTopic(scope.row)" style="width: 90px;">删除</el-button>
                <el-button size="mini" type="primary" @click="updateKeywords(scope.row)" style="margin-top: 10px;margin-left: 0">修改关键词</el-button>
                <el-button size="mini" type="success" @click="openTopicInfo(scope.row)" style="width: 90px;margin-top: 10px;">商品管理</el-button>
                <el-button size="mini" type="success" @click="openGoodsPremium(scope.row)" style="width: 90px;margin-top: 10px">商品溢价</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            style="margin-top: 10px;text-align: center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="params.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
        :visible.sync="keywordsVisible"
        title="关键词"
        width="30%">
      <el-input
          v-model="keywords"
          autosize
          placeholder="关键词"
          type="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="keywordsVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveKeyword">保 存</el-button>
      </span>
    </el-dialog>

    <Goods ref="goods" @closeChange="getList"></Goods>
    <GoodsChange ref="goodsChange" @closeChange="getGoodsChangeList"></GoodsChange>
    <GoodsPremium ref="goodsPremium"></GoodsPremium>

    <el-dialog :close-on-click-modal="false" :visible.sync="dialogEditFormVisible" title="编辑" width="25%">
      <el-form :model="formEdit" :rules="editRules">
        <el-form-item label="选品库名称" label-width="120px" prop="title">
          <el-input v-model="formEdit.title" autocomplete="off" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="选品库区域" label-width="120px" prop="grouping">
          <el-select v-model="formEdit.grouping" placeholder="请选择选品库区域">
            <el-option :value="1" label="选品专区"></el-option>
            <el-option :value="2" label="备用选品库"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editTopic">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {mixins} from "@/views/SelectGoods/mixins";

export default {
  mixins:[mixins],
}
</script>